import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase/config";
import { ref, set, remove, onValue, push, update } from "firebase/database";
import { HeaderWithButtons } from "../components/HeaderWithButtons";

const ArtistHome = ({ user, onLogout }) => {
  const [musicSets, setMusicSets] = useState([]);
  const [bars, setBars] = useState([]);
  const [selectedBar, setSelectedBar] = useState("");
  const [newMusicSet, setNewMusicSet] = useState({
    date: "",
    startTime: "",
    endTime: "",
    barName: "",
    barLocation: "",
    barUID: "",
    artistName: user.name,
    artistUID: user.uid,
    artistType: user.artistType,
    description: "",
  });
  const [editingMusicSet, setEditingMusicSet] = useState(null);
  const [profile, setProfile] = useState({
    instagramUrl: user.instagramUrl || "",
    websiteUrl: user.websiteUrl || "",
  });

  useEffect(() => {
    const musicSetsRef = ref(db, `Colleges/${user.college}/Artists/${user.uid}/musicSets`);
    onValue(musicSetsRef, (snapshot) => {
      const fetchedMusicSets = snapshot.val();
      if (fetchedMusicSets) {
        const musicSetsArray = Object.entries(fetchedMusicSets).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setMusicSets(musicSetsArray);
      } else {
        setMusicSets([]);
      }
    });
  }, [user]);

  useEffect(() => {
    // Fetch bars from the database
    const barsRef = ref(db, `Colleges/${user.college}/Bars`);
    onValue(barsRef, (snapshot) => {
      const fetchedBars = snapshot.val();
      if (fetchedBars) {
        const barsArray = Object.entries(fetchedBars).map(([key, value]) => ({
          uid: key,
          ...value,
        }));
        setBars(barsArray);
      }
    });
  }, [user.college]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const updateProfile = () => {
    const userRef = ref(db, `Colleges/${user.college}/Artists/${user.uid}`);
    update(userRef, {
      instagramUrl: profile.instagramUrl,
      websiteUrl: profile.websiteUrl,
    })
      .then(() => {
        alert("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Failed to update profile. Please try again.");
      });
  };

  const createMusicSet = useCallback(async (newMusicSet) => {
    const musicSetsRef = ref(db, `Colleges/${user.college}/Artists/${user.uid}/musicSets`);
    const newMusicSetRef = push(musicSetsRef);
    const musicSetKey = newMusicSetRef.key;

    const musicSet = {
      ...newMusicSet,
      id: musicSetKey,
      artistType: user.artistType,
      artistName: user.name,
      artistUID: user.uid,
    };

    console.log("musicSet", musicSet);

    const musicSetListRef = ref(db, `Colleges/${user.college}/MusicSets/${musicSetKey}`);

    await set(newMusicSetRef, musicSet);
    await set(musicSetListRef, musicSet);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const musicSetToUpdate = editingMusicSet ? editingMusicSet : newMusicSet;

    if (editingMusicSet) {
      setEditingMusicSet({ ...musicSetToUpdate, [name]: value });
    } else {
      setNewMusicSet({ ...musicSetToUpdate, [name]: value });
    }
  };

  const handleBarSelect = (e) => {
    const barUID = e.target.value;
    const selectedBar = bars.find(bar => bar.uid === barUID);
    if (selectedBar) {
      setSelectedBar(barUID);
      setNewMusicSet(prev => ({
        ...prev,
        barName: selectedBar.name,
        barLocation: selectedBar.location,
        barUID: selectedBar.uid,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const musicSetToSubmit = editingMusicSet ? editingMusicSet : newMusicSet;

    if (!musicSetToSubmit.description.trim() || !musicSetToSubmit.startTime.trim() || !musicSetToSubmit.endTime.trim()) {
      alert("Please fill in all fields.");
      return;
    }

    if (editingMusicSet) {
      updateMusicSet(editingMusicSet);
    } else {
      createMusicSet(musicSetToSubmit);
    }

    // Clear all fields, including date and bar selection
    setNewMusicSet({
      date: "",
      startTime: "",
      endTime: "",
      barName: "",
      barLocation: "",
      barUID: "",
      artistName: user.name,
      artistUID: user.uid,
      artistType: user.artistType,
      description: "",
    });
    setSelectedBar(""); // Clear the selected bar
    setEditingMusicSet(null);
  };

  const updateMusicSet = useCallback(async (musicSet) => {
    const updatedMusicSet = {
      ...musicSet,
      artistName: user.name,
      artistUID: user.uid,
      artistType: user.artistType,
    };

    const musicSetRef = ref(db, `Colleges/${user.college}/Artists/${user.uid}/musicSets/${musicSet.id}`);
    const musicSetListRef = ref(db, `Colleges/${user.college}/MusicSets/${musicSet.id}`);

    await update(musicSetRef, updatedMusicSet);
    await update(musicSetListRef, updatedMusicSet);
  }, [user]);

  const startEditing = (musicSet) => {
    setEditingMusicSet(musicSet);
    setNewMusicSet(musicSet);
  };

  const cancelEditing = () => {
    setEditingMusicSet(null);
    setNewMusicSet({ description: "", startTime: "", endTime: "" });
  };

  const deleteMusicSet = async (id) => {
    if (window.confirm("Are you sure you want to delete this music set?")) {
      const musicSetListRef = ref(db, `Colleges/${user.college}/MusicSets/${id}`);
      const musicSetRef = ref(
        db,
        `Colleges/${user.college}/Artists/${user.uid}/musicSets/${id}`
      );
      await remove(musicSetRef);
      await remove(musicSetListRef);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const date = new Date(2000, 0, 1, hours, minutes);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  };

  return (
    <div style={styles.wrapper}>
      <HeaderWithButtons onLogout={onLogout} />
      <div style={styles.body}>
        <div style={styles.titleContainer}>
          <h1 style={{...styles.title, ...headerStyle}}>{user.name} - {user.artistType}</h1>
        </div>
        <hr style={styles.hr} />
        <h3 style={{...headerStyle, margin: 0}}>Your Music Sets</h3>
        {musicSets.length > 0 ? (
          musicSets.map((musicSet) => (
            <div key={musicSet.id} style={styles.musicSetWrapper}>
              <div style={styles.musicSetText}>
                <span style={styles.barName}>{musicSet.barName}</span>
                <span>{formatDate(musicSet.date)}</span>
                <span>{formatTime(musicSet.startTime)} - {formatTime(musicSet.endTime)}</span>
                <span style={styles.description}>{musicSet.description}</span>
              </div>
              <div style={styles.buttonGroup}>
                <button 
                  onClick={() => editingMusicSet && editingMusicSet.id === musicSet.id ? cancelEditing() : startEditing(musicSet)} 
                  style={styles.editButton}
                >
                  {editingMusicSet && editingMusicSet.id === musicSet.id ? "Cancel" : "Edit"}
                </button>
                <button onClick={() => deleteMusicSet(musicSet.id)} style={styles.deleteButton}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No music sets available. Add a new music set below!</p>
        )}
        <hr style={styles.hr} />

        <div style={styles.sideBySideContainer}>
          <div style={editingMusicSet ? styles.formContainerEditing : styles.formContainer}>
            <h3 style={{...headerStyle, margin: "5px 0"}}>{editingMusicSet ? "Edit Music Set" : "Create a New Music Set"}</h3>
            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={styles.formRow}>
                <div style={styles.formColumn}>
                  <label htmlFor="barSelect" style={styles.label}>Select a Bar</label>
                  <select
                    id="barSelect"
                    name="barUID"
                    value={editingMusicSet ? editingMusicSet.barUID : selectedBar}
                    onChange={handleBarSelect}
                    style={styles.input}
                    required
                  >
                    <option value="">Select a bar</option>
                    {bars.map((bar) => (
                      <option key={bar.uid} value={bar.uid}>
                        {bar.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={styles.formColumn}>
                  <label htmlFor="date" style={styles.label}>Select Date</label>
                  <input
                    id="date"
                    type="date"
                    name="date"
                    value={editingMusicSet ? editingMusicSet.date : newMusicSet.date}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>

              <div style={styles.formRow}>
                <div style={styles.formColumn}>
                  <label htmlFor="startTime" style={styles.label}>Start Time</label>
                  <input
                    id="startTime"
                    type="time"
                    name="startTime"
                    value={editingMusicSet ? editingMusicSet.startTime : newMusicSet.startTime}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
                <div style={styles.formColumn}>
                  <label htmlFor="endTime" style={styles.label}>End Time</label>
                  <input
                    id="endTime"
                    type="time"
                    name="endTime"
                    value={editingMusicSet ? editingMusicSet.endTime : newMusicSet.endTime}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                  />
                </div>
              </div>

              <div style={styles.formColumn}>
                <label htmlFor="description" style={styles.label}>Description</label>
                <input
                  id="description"
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={editingMusicSet ? editingMusicSet.description : newMusicSet.description}
                  onChange={handleInputChange}
                  style={styles.input}
                />
              </div>

              <button type="submit" style={styles.button}>
                {editingMusicSet ? "Update Music Set" : "Create Music Set"}
              </button>
            </form>
          </div>

          <div style={styles.profileContainer}>
            <h3 style={{...headerStyle, margin: "5px 0"}}>Profile Information</h3>
            <div style={styles.profileSection}>
              <div style={styles.formColumn}>
                <label htmlFor="instagramUrl" style={styles.label}>Instagram URL</label>
                <input
                  id="instagramUrl"
                  type="text"
                  name="instagramUrl"
                  placeholder="Instagram URL"
                  value={profile.instagramUrl}
                  onChange={handleProfileChange}
                  style={styles.input}
                />
              </div>
              <div style={styles.formColumn}>
                <label htmlFor="websiteUrl" style={styles.label}>Website URL</label>
                <input
                  id="websiteUrl"
                  type="text"
                  name="websiteUrl"
                  placeholder="Website URL"
                  value={profile.websiteUrl}
                  onChange={handleProfileChange}
                  style={styles.input}
                />
              </div>
            </div>
            
            <div style={styles.buttonContainer}>
              <button onClick={updateProfile} style={styles.button}>
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistHome;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  body: {
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hr: {
    border: "none",
    height: 1,
    backgroundColor: "black",
    margin: "20px 0",
  },
  musicSetWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    border: "1px solid #ccc",
    borderRadius: 8,
    margin: "10px 0",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  musicSetText: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    flex: 1, // This ensures the text takes up available space
    paddingRight: '20px',
  },
  barName: {
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  description: {
    fontStyle: "italic",
    color: "#555",
  },
  buttonGroup: {
    display: "flex",
    gap: 10,
  },
  editButton: {
    padding: "8px 12px",
    backgroundColor: "#fff",
    color: "#333",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "background-color 0.2s",
    border: "1px solid #333",
  },
  deleteButton: {
    padding: "8px 12px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "background-color 0.2s",
  },
  cancelButton: {
    padding: 10,
    backgroundColor: "#6c757d",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    width: "100%",
  },
  sideBySideContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    alignItems: "stretch",
  },
  formContainer: {
    flex: 1,
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  formContainerEditing: {
    flex: 1,
    padding: 10,
    border: "3px solid #28a745",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  profileContainer: {
    flex: 1,
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // This will push the button to the bottom
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },
  formRow: {
    display: "flex",
    gap: "15px",
  },
  formColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  button: {
    width: "100%",
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "none",
    backgroundColor: "#333",
    color: "#fff",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  profileSection: {
    display: "flex",
    flexDirection: "column",
    gap: "15px", // This adds space between the two input fields
  },
  buttonContainer: {
    marginTop: "auto", // This pushes the button to the bottom
  },
  title: {
    margin: "10px 0",
  },
};

const headerStyle = {
  color: "#333",
};
