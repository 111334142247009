import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../firebase/config";
import { ref, set, onValue } from "firebase/database";
import { Header } from "../components/Header";

const COLLEGES = ["Ohio State University"];

const BarRegistration = ({ setUser }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    college: "",
    name: "",
    instagramUrl: "",
    websiteUrl: "",
    location: "",
  });
  const [locationOptions, setLocationOptions] = useState([]);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (formData.college) {
      const locationRef = ref(db, `Colleges/${formData.college}/LocationOptions`);
      onValue(locationRef, (snapshot) => {
        const locations = snapshot.val();
        if (locations) {
          setLocationOptions(Object.values(locations));
        }
      });
    }
  }, [formData.college]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword, college, name, instagramUrl, websiteUrl, location } = formData;

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (!college || !name) {
      alert("Please select a college and enter your bar name.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userModel = { uid: user.uid, email, college, name, instagramUrl, websiteUrl, location };

      await set(ref(db, `Colleges/${college}/Bars/${user.uid}`), userModel);
      alert("Registration successful!");
      setUser(userModel);
      navigate("/bar/home");
    } catch (error) {
      console.error("Error creating user:", error.message);
      let errorMessage = "Registration failed. Please try again.";
      switch (error.message) {
        case "Firebase: Error (auth/email-already-in-use).":
          errorMessage = "Email already in use. Please login or try another email.";
          break;
        case "Firebase: Error (auth/invalid-email).":
          errorMessage = "Invalid email address. Please enter another email.";
          break;
        case "Firebase: Password should be at least 6 characters (auth/weak-password).":
          errorMessage = "Weak password. Please use at least 6 characters.";
          break;
        default:
          errorMessage = "Registration failed. Please try again.";
      }
      alert(errorMessage);
    }
  };

  return (
    <div style={styles.wrapper}>
      <Header />
      <div style={styles.body}>
        <h2>Register</h2>
        <form onSubmit={handleRegister} style={styles.form}>
          <input
            type="text"
            name="name"
            placeholder="Bar Name"
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <select
            name="college"
            value={formData.college}
            onChange={handleChange}
            style={styles.input}
            required
          >
            <option value="" disabled>Select your college</option>
            {COLLEGES.map((college) => (
              <option key={college} value={college}>{college}</option>
            ))}
          </select>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            style={styles.input}
            required
          />
          <input
            type="text"
            name="instagramUrl"
            placeholder="Instagram URL"
            value={formData.instagramUrl}
            onChange={handleChange}
            style={styles.input}
          />
          <input
            type="text"
            name="websiteUrl"
            placeholder="Website URL"
            value={formData.websiteUrl}
            onChange={handleChange}
            style={styles.input}
          />
          {locationOptions.length > 0 && (
            <select
              name="location"
              value={formData.location}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="" disabled>Select Location</option>
              {locationOptions.map((loc, index) => (
                <option key={index} value={loc}>{loc}</option>
              ))}
            </select>
          )}
          <button type="submit" style={styles.button}>Register</button>
        </form>
        <a href="/bar/login" style={styles.link}>Go back to login</a>
        <a href="/landing" style={styles.link2}>Return to selection page</a>
      </div>
    </div>
  );
};

export default BarRegistration;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#fff",
  },
  body: {
    maxWidth: 500,
    width: "100%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    marginTop: 50,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  input: {
    width: "100%",
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "100%",
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "none",
    backgroundColor: "#333",
    color: "#fff",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  link: {
    marginTop: 20,
    textDecoration: "underline",
    color: "#007BFF",
    cursor: "pointer",
  },
  link2: {
    marginTop: 20,
    textDecoration: "underline",
    color: "black",
    cursor: "pointer",
  },
};
