import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from './components/Header';

function LandingPage({ isBarUser, isArtistUser, onBarRedirect, onArtistRedirect }) {
    useEffect(() => {
        if (isBarUser) {
            onBarRedirect();
        } else if (isArtistUser) {
            onArtistRedirect();
        }
    }, [isBarUser, isArtistUser, onBarRedirect, onArtistRedirect]);

    const navigate = useNavigate();
    const navigateTo = (path) => () => navigate(path);

    return (
        <div style={styles.wrapper}>
            <Header />
            <div style={styles.body}>
                <h1 style={styles.title}>Welcome to College Sips</h1>
                <div style={styles.navContainer}>
                    {[
                        { title: 'Bar Portal', path: '/bar/login' },
                        { title: 'Artist Portal', path: '/artist/login' }
                    ].map(({ title, path }) => (
                        <div key={title} style={styles.navItemContainer}>
                            <div style={styles.navItem}>
                                <h2 style={styles.navTitle}>{title}</h2>
                                <button style={styles.navButton} onClick={navigateTo(path)}>
                                    Go to {title}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LandingPage;

const styles = {
    wrapper: {},
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
    },
    title: {
        fontSize: '2rem',
        color: '#333',
        marginBottom: '20px',
    },
    navContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '900px',
    },
    navItemContainer: {
        height: '400px',
        width: '300px',
        backgroundColor: '#333',
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        margin: '10px',
    },
    navItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: 15,
        height: '100%',
        width: '100%',
    },
    navTitle: {
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '20px',
    },
    navButton: {
        padding: '10px 20px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
};
