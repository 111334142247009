import React, { useState } from "react";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const ChangePassword = ({ user }) => {
    const [email, setEmail] = useState(user.email || "");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setError("");

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await updatePassword(user, newPassword);
            alert("Password changed successfully!");
            navigate("/bar/home");
        } catch (error) {
            switch (error.code) {
                case "auth/wrong-password":
                    setError("Invalid current password.");
                    break;
                case "auth/user-not-found":
                    setError("No user found with this email.");
                    break;
                case "auth/invalid-email":
                    setError("Invalid email format.");
                    break;
                default:
                    setError("An unexpected error occurred. Please try again.");
            }
        }
    };

    return (
        <div style={styles.wrapper}>
            <h1>Change Password</h1>
            <form onSubmit={handleChangePassword} style={styles.form}>
                <label style={styles.label}>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                        required
                        disabled
                    />
                </label>
                <label style={styles.label}>
                    Current Password:
                    <div style={styles.passwordWrapper}>
                        <input
                            type={passwordVisible ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                            required
                        />
                        <button
                            type="button"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={styles.viewButton}
                        >
                            {passwordVisible ? "Hide" : "View"}
                        </button>
                    </div>
                </label>
                <label style={styles.label}>
                    New Password:
                    <div style={styles.passwordWrapper}>
                        <input
                            type={newPasswordVisible ? "text" : "password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            style={styles.input}
                            required
                        />
                        <button
                            type="button"
                            onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                            style={styles.viewButton}
                        >
                            {newPasswordVisible ? "Hide" : "View"}
                        </button>
                    </div>
                </label>
                <button type="submit" style={styles.button}>
                    Submit
                </button>
                {error && <p style={styles.error}>{error}</p>}
            </form>
        </div>
    );
};

const styles = {
    wrapper: {
        display: "flex",
        flexDirection: "column",
        padding: 20,
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        marginBottom: 10,
        width: 200
    },
    input: {
        padding: 8,
        borderRadius: 4,
        border: "1px solid #ccc",
        flex: 1,
    },
    passwordWrapper: {
        display: "flex",
        alignItems: "center",
    },
    viewButton: {
        marginLeft: 10,
        padding: 5,
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
    },
    button: {
        padding: 10,
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
        width: 200,
    },
    error: {
        color: "red",
        marginTop: 10,
    },
};
