import React from "react";
//import { useNavigate } from "react-router-dom";

export const Header = () => {
    //const navigate = useNavigate();

    return (
        <div style={styles.wrapper}>
            <img src={require('../assets/logo-transparent.png')} alt="Logo" style={styles.logo} />
            <h2 style={styles.text}>College Sips</h2>
        </div>
    );
};

const styles = {
    wrapper: {
        width: '100%',
        height: '60px',
        backgroundColor: '#f5f5f5',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        display: 'flex',
        padding: '0 10px',
    },
    logo: {
        height: '80%',
    },
    text: {
        color: '#333',
        margin: '0 20px'
    }
};
