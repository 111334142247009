import React from "react";
import { useNavigate } from "react-router-dom";

export const HeaderWithButtons = ({ onLogout }) => {
  const navigate = useNavigate();

  return (
    <div style={styles.wrapper}>
      <div style={styles.left}>
        <img
          src={require("../assets/logo-transparent.png")}
          alt="Logo"
          style={styles.logo}
        />
        <h2 style={styles.text}>College Sips</h2>
      </div>
      <div style={styles.right}>
        <button
          onClick={() => {
            navigate("/changeEmail");
          }}
          style={{ ...styles.button}}
        >
          Change email
        </button>
        <button
          onClick={() => {
            navigate("/changePassword");
          }}
          style={styles.button}
        >
          Change password
        </button>
        <button onClick={onLogout} style={styles.button}>
          Logout
        </button>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    height: "60px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ccc",
    display: "flex",
    padding: "0 0 0 10px",
    justifyContent: "space-between",
  },
  left: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    display: "flex",
  },
  right: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    display: "flex",
  },
  logo: {
    height: "80%",
  },
  text: {
    color: "#333",
    margin: "0 20px",
  },
  button: {
    height: '100%',
    fontSize: 16,
    cursor: "pointer",
    backgroundColor: 'transparent',
    padding: "0 20px 0 20px",
    border: 'none',
    borderLeft: '1px solid #ccc',
  },
};
