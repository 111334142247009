import React, { useState } from "react";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ref, update } from "firebase/database";
import { db } from "../firebase/config";

export const ChangeEmail = ({ user, setUser }) => {
    const [email, setEmail] = useState(user.email || "");
    const [password, setPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [error, setError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    //console.log(user);

    const handleChangeEmail = async (e) => {
        e.preventDefault();
        setError("");

        try {
            // Re-authenticate the user with their current email and password
            console.log(email);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log(userCredential);
            const authenticatedUser = userCredential.user;

            // Update the user's email in Firebase Authentication
            await updateEmail(authenticatedUser, newEmail);

            // Update the email in the database
            const userRef = ref(db, `Colleges/${user.college}/Bars/${user.uid}`);
            await update(userRef, {
                email: newEmail,
            });

            // Update the user state and navigate back to home
            setUser({ ...user, email: newEmail });
            alert("Email changed successfully!");
            navigate("/bar/home");
        } catch (error) {
            // Handle errors and provide user-friendly messages
            switch (error.code) {
                case "auth/wrong-password":
                    setError("Invalid password.");
                    break;
                case "auth/user-not-found":
                    setError("No user found with this email.");
                    break;
                case "auth/invalid-email":
                    setError("Invalid email format.");
                    break;
                case "auth/email-already-in-use":
                    setError("This email is already in use.");
                    break;
                default:
                    setError("An unexpected error occurred. Please try again.");
            }
        }
    };

    return (
        <div style={styles.wrapper}>
            <h1>Change Email</h1>
            <form onSubmit={handleChangeEmail} style={styles.form}>
                <label style={styles.label}>
                    Current Email:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={styles.input}
                        required
                        disabled
                    />
                </label>
                <label style={styles.label}>
                    Password:
                    <div style={styles.passwordWrapper}>
                        <input
                            type={passwordVisible ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                            required
                            
                        />
                        <button
                            type="button"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={styles.viewButton}
                        >
                            {passwordVisible ? "Hide" : "View"}
                        </button>
                    </div>
                </label>
                <label style={styles.label}>
                    New Email:
                    <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        style={styles.input}
                        required
                    />
                </label>
                <button type="submit" style={styles.button}>
                    Submit
                </button>
                {error && <p style={styles.error}>{error}</p>}
            </form>
        </div>
    );
};

const styles = {
    wrapper: {
        display: "flex",
        flexDirection: "column",
        padding: 20,
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        marginBottom: 10,
        width: 200,
    },
    input: {
        padding: 8,
        borderRadius: 4,
        border: "1px solid #ccc",
        flex: 1,
    },
    passwordWrapper: {
        display: "flex",
        alignItems: "center",
    },
    viewButton: {
        marginLeft: 10,
        padding: 5,
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
    },
    button: {
        padding: 10,
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: 4,
        cursor: "pointer",
        width: 200,
    },
    error: {
        color: "red",
        marginTop: 10,
    },
};
